// @ts-check
import { Canvas } from "@shopstory/core/react"
import React from "react"
import styled from "@emotion/styled"
import { config } from "../shopstory/config"
import { TeklaShopstoryProvider } from "../shopstory/Provider"

function ShopstoryEditorPage() {
  return (
    <Wrapper>
      <TeklaShopstoryProvider>
        <Canvas config={config} />
      </TeklaShopstoryProvider>
    </Wrapper>
  )
}

export default ShopstoryEditorPage

const Wrapper = styled.div`
  // Override margin-bottom set in layout styles because it spoils editor selection
  iframe {
    margin-bottom: 0;
  }
`
