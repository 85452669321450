// @ts-check

/** @type {import('@shopstory/core/contentful').ContentfulParams} */
const contentfulParams = {
  accessToken:
    process.env.CONTENTFUL_ACCESS_TOKEN ||
    process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  environment:
    process.env.CONTENTFUL_ENVIRONMENT ||
    process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
  previewAccessToken:
    process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN ||
    process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  space:
    process.env.CONTENTFUL_SPACE_ID || process.env.GATSBY_CONTENTFUL_SPACE_ID,
  enablePreview: true,
}

module.exports = { contentfulParams }
