// @ts-check
const { ApolloClient, InMemoryCache } = require("@apollo/client")
const { HttpLink } = require("apollo-link-http")
const { onError } = require("apollo-link-error")
const { ApolloLink } = require("apollo-link")

const apolloClient = new ApolloClient({
  // @ts-expect-error
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    new HttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}`,
      credentials: "same-origin",
      headers: {
        Authorization: `Bearer ${
          process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN ??
          process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN
        }`,
      },
      // @ts-expect-error
      fetch:
        typeof window === "undefined"
          ? require("node-fetch").default
          : window.fetch,
    }),
  ]),
  cache: new InMemoryCache(),
})

module.exports = { apolloClient }
